import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'

export interface NavigationListItemProps {
  icon: React.ReactElement
  text: string
  subText?: string
  drawerOpen?: boolean
  selected?: boolean
  onClick?: () => void
}

export const NavigationListItem: React.FC<NavigationListItemProps> = ({
  drawerOpen,
  icon,
  text,
  subText,
  selected,
  onClick,
}) => (
  <ListItem key={text} disablePadding sx={{ display: 'block' }}>
    <Tooltip title={text} placement="right" disableHoverListener={drawerOpen}>
      <ListItemButton
        onClick={onClick}
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? 'initial' : 'center',
          backgroundColor: selected ? 'primary.main' : 'transparent',
          color: 'primary.contrastText',
          '& .MuiListItemIcon-root': {
            color: 'primary.contrastText',
          },
          boxShadow: selected ? 2 : 0,
          px: 2.5,
          '&:hover': {
            backgroundColor: 'primary.light',
            color: 'primary.contrastText',
            boxShadow: 1,
          },

        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} secondary={subText} />
      </ListItemButton>
    </Tooltip>
  </ListItem>
)
export default NavigationListItem
